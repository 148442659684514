import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Showcase = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map((item) => (
      <div key={item.text} className="column is-4">
        <section className="section">
          <div className="has-text-centered content">
            <div
              style={{
                width: '280px',
                display: 'inline-block',
              }}
            >
              <Link to={item.linkTo} title={item.text}>
                <img alt={item.text} src={item.src} />
                <h1 style={{ fontFamily: 'Syncopate', textTransform: 'uppercase' }}>{item.text}</h1>
              </Link>
            </div>
          </div>
        </section>
      </div>
    ))}
  </div>
);

Showcase.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
};

export default Showcase;
