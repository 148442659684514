import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import Showcase from '../components/Showcase';

import FireVideo from '../../static/img/fire.mp4';
import * as styles from './index-page.module.css';

import '../components/all.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';

import AssetAhjud from '../../static/img/showcase/ahjud.jpg';
import AssetKaminad from '../../static/img/showcase/kaminad.jpg';
import AssetPliidid from '../../static/img/showcase/pliidid.jpg';

const renderHero = () => (
  <div>
    <div className={styles.videoContainer}>
      <video loop autoPlay muted className={styles.videoBg}>
        <source src={FireVideo} type="video/mp4" />
      </video>

      <div className={styles.videoHero}>
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={{
            color: 'white',
            lineHeight: '1',
            padding: '0.75em',
          }}
        >
          Luues soojust ja hubasust
        </h1>
      </div>
    </div>
  </div>
);

const renderShowcase = () => {
  const showCaseItems = [
    { src: AssetAhjud, text: 'Ahjud', linkTo: 'ahjud' },
    { src: AssetPliidid, text: 'Pliidid', linkTo: 'pliidid' },
    { src: AssetKaminad, text: 'Kaminad', linkTo: 'kaminad' },
    // { src: '', text: 'Korstnad', linkTo: 'korstnad' },
  ];

  return <Showcase gridItems={showCaseItems} />;
};

const renderAbout = () => (
  <section className="section has-background-light">
    <div className="container">
      <div className="columns">
        <div className="column is-12">
          <div className="content has-text-centered">
            <h2>Ahjud Pro OÜ on spetsialiseerunud pottsepatöödele</h2>
          </div>
        </div>
      </div>
      <div className="columns mt-5">
        <div className="column is-3">
          <div className="content has-text-justified">
            <div className="icon-text">
              <span className="icon is-large mb-5 ma ahjud-primary ahjud-centered">
                <i className="fas fa-4x fa-trophy" />
              </span>
              <span>Pottsepatöid teeb oma ala meister, kel kogemust 20+ aastat  ning omab kutsetunnistust "Pottseppmeister tase 5”.</span>
            </div>
          </div>
        </div>
        <div className="column is-3">
          <div className="content has-text-justified">
            <div className="icon-text">
              <span className="icon is-large mb-5 ma" style={{ margin: 'auto', color: '#ff4400' }}>
                <i className="fas fa-4x fa-check" />
              </span>
              <span>Küttesüsteemide ekspertiis küttekolde ohutuks kasutamiseks ja elamu kasutusloa taotlemiseks.</span>
            </div>
          </div>
        </div>
        <div className="column is-3">
          <div className="content has-text-justified">
            <div className="icon-text">
              <span className="icon is-large mb-5 ma" style={{ margin: 'auto', color: '#ff4400' }}>
                <i className="fas fa-4x fa-lightbulb" />
              </span>
              <span>Klassikaliste küttekollete ehitamine kui ka eritellimused.</span>
            </div>
          </div>
        </div>
        <div className="column is-3">
          <div className="content has-text-justified">
            <div className="icon-text">
              <span className="icon is-large mb-5 ma" style={{ margin: 'auto', color: '#ff4400' }}>
                <i className="fas fa-4x fa-map" />
              </span>
              <span>Pottsepatööd ja -teenused Pärnumaal, üle Eesti.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const renderDescription = () => (
  <section className="section has-background-light">
    <div className="block">
      Oleme väikeettevõte Eesti turul kelle tegevusalaks on kõikvõimalikud pottsepatööd.
      Pakume professionaalset nõustamist ja kvaliteetset teenust küttekollete valdkonnas.
      Meie põhiline tööpiirkond on Pärnumaa, kuid vajadusel pakume teenust ka üle Eesti.
    </div>
    <div className="block">
      Meie meistril on olemas on kutsetunnistus "Pottseppmeister tase V" ning töökogemust üle 20 aasta. Aastatega on valminud sadu erinevaid küttekoldeid.
    </div>
    <div className="block">
      Kõigi teostatud töödega kaasneb garantii koos muu dokumentatsiooniga.
    </div>
    <div className="block">
      Teostame ka küttekollete ekspertiishinnagut. Koostame detailse hinapakkumise ja sobivuse korral teostame kõik vajalikud tööd.
    </div>
  </section>
);

const renderInfo = () => (
  <div>
    <p>
      Enne kütteperioodi algust vaata küttesüsteem kriitilise pilguga üle – ega kuskilt suitsu sisse ei aja; ega lõõris, pliidis, soojamüüris, ahjus või korstnas pole pragusid; ega korsten pole pigitunud ja kas tahmaluugid on kindlalt kinni. Kui kütad maja puiduga läbi aasta, puhasta kütteseadmeid kaks korda aastas, kui hooajaliselt sügisest kevadeni, siis üks kord enne kütteperioodi algust ja gaasikütteseadmeid kord aastas. Ahju küta kvaliteetse halupuuga. Kui viskad küttekoldesse halva kvaliteediga küttematerjale (okaspuu, pinnud jne), siis hoolda ka küttekeha tihedamini. Eramu korstnat võib majaomanik ise puhastada, kuid 2010. aasta 1. septembrist kehtiv tuleohutuse seadus sätestab, et igal viiendal aastal peab korstna puhastama ja kütteseadme ohutust kontrollima kutseline korstnapühkija, kes annab kütteseadme puhastamise ja kontrolli kohta akti. Aktil on kirjas tehtud tööd ja korstnapühkija kutsetunnistuse number. Kutsetunnistuse kehtivust saab kontrollida aadressil http://kutsekoda.ee/et/ kutseregister/kutsetunnistused. Korstnapühkijate kontakte küsi pääste infotelefonilt 1524, need leiab ka aadressilt www. korsten.ee.
    </p>
  </div>
);

const IndexPage = () => (
  <Layout isIndex>
    {renderHero()}
    {renderAbout()}
    {renderShowcase()}

  </Layout>
);

export default IndexPage;
